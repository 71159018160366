var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d247776099f46ff164fb0bdbfa59393b24ef5505"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import { tracesSampler } from './src/utils/sentry/tracesSampler';

const { publicRuntimeConfig } = getConfig();
const environment = process.env.NEXT_PUBLIC_SENTRY_ENV || 'development';

Sentry.init({
    dsn: publicRuntimeConfig.SENTRY_DSN,
    environment: environment,
    tracesSampler,
});
